<template>
    <div v-if="columnData">
        {{dateHelper.decodeDate(columnData)}}
    </div>
</template>

<script>
export default {
    props: {
        columnData: String,
    }
}
</script>

<style scoped>

</style>